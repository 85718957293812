module.exports = {
  // TODO: Figure out which fields are actually needed here then remove the rest from all properties
  gatsbyConfig: {
    property: 'killington',
    siteName: 'Killington - Welcome to the Beast',
    siteUrl: 'https://www.killington.com',
    reCaptchaDBPropertyName: 'Killington',
    reCaptchaPublicKey: '6LfiiYIUAAAAAEvSxGg7A_ZOQRemWMgGBoTr0Z5P',
    validHotjarPages: ['/ecomm-test', '/lift-tickets-direct', '/plan-your-trip/tickets-passes/lift-tickets'],
    hidePropertyList: true,
    color: '#009CDB',
    facebook: 'killingtonresort',
    instagram: 'killingtonmtn',
    twitter: 'KillingtonMtn',
    youtube: 'KillingtonMountain',
    robots: [
      '/inntopia-operator-booking/',
      '/fit-operator-booking/',
      '/bike-park-tickets/',
      '/lease/',
      '/tgr-bogo/',
      '/rewards/',
      '/news/',
      '/landing/',
      '/tv/',
      '/ssr/',
      '/lift-tickets-direct',
    ],
    dynamicTypes: [
      'pressRelease',
      'event',
      'blog',
    ],
    ignore404Routes: [
      '/culture/social-blog/insider-blog',
      '/culture/social-blog/4241-stories',
      '/things-to-do/events/events-calendar',
      '/media-room/press-releases/all-press-releases',
    ],
    metaImageFallbackUrl: 'https://cms.killington.powdr-gatsby.com/sites/killington/files/2022-11/hero-test.jpg',
  },
  // TODO: Good way of managing API keys per site, maybe use for formbucket and mailchimp?
  contentfulConfig: {
    spaceId: '38vc2unmqsq5',
    accessToken: 'D2b0ZG2uKbPvWdW38GS0y3_hQN9AxH-iJnKijjoI0cA',
  },
};
